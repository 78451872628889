import { EMAIL_CONFIG } from './config';
import type { MailjetMessage } from './types';

export async function sendMailjetEmail(message: MailjetMessage): Promise<any> {
  const { apiKey, apiSecret, apiUrl } = EMAIL_CONFIG.mailjet;

  if (!apiKey || !apiSecret) {
    console.warn('Mailjet API credentials not found - email functionality will be disabled');
    return null;
  }

  try {
    const response = await fetch(`${apiUrl}/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${apiKey}:${apiSecret}`)}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        Messages: [message]
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.ErrorMessage || error.message || 'Failed to send email');
    }

    return response.json();
  } catch (error) {
    console.error('Failed to send email via Mailjet:', error);
    throw error;
  }
}