// Email configuration constants
export const EMAIL_CONFIG = {
  from: {
    email: 'noreply@packmyride.com',
    name: 'PackMyRide'
  },
  mailjet: {
    apiKey: import.meta.env.VITE_MAILJET_API_KEY,
    apiSecret: import.meta.env.VITE_MAILJET_API_SECRET,
    apiUrl: 'https://api.mailjet.com/v3.1'
  }
} as const;