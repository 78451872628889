import { sendMailjetEmail } from './client';
import { EMAIL_CONFIG } from './config';
import { templates } from './templates';
import { logEvent } from '../db/eventLogs';
import type { EmailOptions } from './types';
import toast from 'react-hot-toast';

export async function sendEmail({ 
  to, 
  toName,
  template, 
  data = {}, 
  from = EMAIL_CONFIG.from
}: EmailOptions) {
  try {
    // Add common template data
    const templateData = {
      year: new Date().getFullYear(),
      supportEmail: from.email,
      siteUrl: window.location.origin,
      ...data
    };

    const response = await sendMailjetEmail({
      From: from,
      To: [{ Email: to, Name: toName }],
      TemplateID: templates[template].id,
      TemplateLanguage: true,
      Variables: templateData
    });

    await logEvent({
      type: 'email',
      action: `SEND_${template}`,
      targetId: to,
      metadata: {
        template,
        data: templateData
      },
      status: 'success'
    });

    return response;
  } catch (error) {
    console.error('Failed to send email:', error);
    
    await logEvent({
      type: 'email',
      action: `SEND_${template}`,
      targetId: to,
      metadata: {
        template,
        error: error instanceof Error ? error.message : 'Unknown error'
      },
      status: 'error'
    });

    toast.error('Failed to send email');
    throw error;
  }
}