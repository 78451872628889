export const TEMPLATE_IDS = {
  WELCOME: 12564351,
  BOOKING_REQUEST: 12564414,
  BOOKING_CONFIRMATION: 12564540,
  VERIFY_EMAIL: 4815162345,
  BOOKING_CANCELLED: 4815162346,
  BOOKING_REMINDER: 4815162347,
  PASSWORD_RESET: 4815162348,
} as const;

export type TemplateId = keyof typeof TEMPLATE_IDS;

export interface EmailTemplate {
  id: number;
  name: string;
  subject: string;
  variables: string[];
}

export const templates: Record<TemplateId, EmailTemplate> = {
  WELCOME: {
    id: TEMPLATE_IDS.WELCOME,
    name: 'Welcome Email',
    subject: '{% if firstName %}Welcome to PackMyRide, {{firstName}}! 🚲{% else %}Welcome to PackMyRide! 🚲{% endif %}',
    variables: ['firstName', 'siteUrl', 'supportEmail']
  },
  BOOKING_REQUEST: {
    id: TEMPLATE_IDS.BOOKING_REQUEST,
    name: 'New Booking Request',
    subject: 'New Booking Request for {{ var:bagModel }} 📬',
    variables: ['ownerName', 'renterName', 'bagModel', 'startDate', 'endDate', 'totalPrice']
  },
  BOOKING_CONFIRMATION: {
    id: TEMPLATE_IDS.BOOKING_CONFIRMATION,
    name: 'Booking Confirmation',
    subject: '{% if bagModel %}{{ var:bagModel }} Booking Confirmed! 🎉{% else %}Booking Confirmed! 🎉{% endif %}',
    variables: ['renterName', 'bagModel', 'startDate', 'endDate', 'pickupLocation', 'totalPrice']
  },
  VERIFY_EMAIL: {
    id: TEMPLATE_IDS.VERIFY_EMAIL,
    name: 'Email Verification',
    subject: 'Please verify your email address ✉️',
    variables: ['firstName', 'verificationUrl']
  },
  BOOKING_CANCELLED: {
    id: TEMPLATE_IDS.BOOKING_CANCELLED,
    name: 'Booking Cancelled',
    subject: '{% if bagModel %}{{ var:bagModel }} Booking Cancelled{% else %}Booking Cancelled{% endif %}',
    variables: ['name', 'bagModel', 'startDate', 'endDate', 'refundAmount']
  },
  BOOKING_REMINDER: {
    id: TEMPLATE_IDS.BOOKING_REMINDER,
    name: 'Booking Reminder',
    subject: 'Reminder: Your {{ var:bagModel }} rental is coming up',
    variables: ['name', 'bagModel', 'startDate', 'endDate', 'pickupLocation']
  },
  PASSWORD_RESET: {
    id: TEMPLATE_IDS.PASSWORD_RESET,
    name: 'Password Reset',
    subject: 'Reset your PackMyRide password',
    variables: ['name', 'resetUrl']
  }
};